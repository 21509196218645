import React, { memo, useEffect } from 'react';
import { number, object, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { checkIsEditQuoteFlow, currencyToLocale } from '../../global/utils/commonUtils';
import { useQuotesState } from '../../quotes/context';
import CartSummaryView from './components/cartSummaryView/CartSummaryView';
import CheckoutSummaryView from './components/checkoutSummaryView/CheckoutSummaryView';
import QuoteSummaryView from './components/quoteSummaryView/QuoteSummaryView';
import { FACTORYSOURCE } from './constants/orderSummaryFactoryConstants';
import useOrderSummaryFactory from './hooks/useOrderSummaryFactory';
import useQuoteSummary from './hooks/useQuoteSummary';
import { EVENT_PAGE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Page';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import useAnalytics from '../../../hooks/useAnalytics';
import { useCartState } from '../../../contexts/cart';

const OrderSummaryFactory = props => {
    const { title, source, formStep, currentStep } = props;
    const orderSummaryIntl = useIntl();
    const [{ loaders }] = useCartState();
    const { isEstimatesLoading } = loaders || {};
    const [{ quoteDetailsData }] = useQuotesState();
    const { quoteSummary } = useQuoteSummary();
    const { sendEventsForP2PApplication } = useAnalyticsContext();
    const [{ payloadQuoteDetailsPageLoadActionAnalytics }] = useAnalytics();
    const {
        orderSummaryDetails,
        showPerDay,
        deliveryChargeFlag,
        showDeliveryEstimate,
        handleRoundTripAndEstimateFlow,
        isRatesFromTier2Radius,
        shouldShowTbdAlert,
        isInStorePickupFromSessionStorage,
        isDelivery,
        dispatch
    } = useOrderSummaryFactory(props);

    const isQuote = source === FACTORYSOURCE.QUOTE;
    const {
        checkoutSubtotal: subtotalValue,
        rentalSubtotal,
        purchaseSubtotal,
        rentalProtectionPlan,
        prepayFuelOption,
        environmentalServiceFee: envFees,
        otherFees,
        taxes,
        estimatedSubtotal,
        deliveryPickUpCharges
    } = isQuote ? quoteSummary : orderSummaryDetails;

    useEffect(() => {
        const isEditQuoteFlow = checkIsEditQuoteFlow();
        if (isQuote && estimatedSubtotal > 0 && !isEditQuoteFlow) {
            sendEventsForP2PApplication(
                EVENT_PAGE_NAMES_CONFIG.QUOTE_DETAIL_VIEWED,
                payloadQuoteDetailsPageLoadActionAnalytics()
            );
        }
    }, [quoteSummary]);

    const cartData = {
        title,
        rentalSubtotal: showPerDay(rentalSubtotal),
        purchaseSubtotal: showPerDay(purchaseSubtotal),
        rentalProtectionPlan: currencyToLocale(rentalProtectionPlan),
        prepayFuelOption: currencyToLocale(prepayFuelOption),
        envFees: currencyToLocale(envFees),
        otherFees: currencyToLocale(otherFees),
        taxes: currencyToLocale(taxes),
        subtotalValue: currencyToLocale(subtotalValue),
        estimatedSubtotal: showPerDay(estimatedSubtotal),
        deliveryPickUpCharges: currencyToLocale(deliveryPickUpCharges),
        showDeliveryEstimate,
        dispatch
    };

    const quotesData = {
        title,
        rentalSubtotal: currencyToLocale(rentalSubtotal),
        purchaseSubtotal: currencyToLocale(purchaseSubtotal),
        rentalProtectionPlan: currencyToLocale(rentalProtectionPlan),
        prepayFuelOption: currencyToLocale(prepayFuelOption),
        envFees: currencyToLocale(envFees),
        otherFees: currencyToLocale(otherFees),
        taxes: currencyToLocale(taxes),
        subtotalValue: currencyToLocale(subtotalValue),
        estimatedSubtotal: currencyToLocale(estimatedSubtotal),
        deliveryPickUpCharges: currencyToLocale(deliveryPickUpCharges),
        showDeliveryEstimate,
        dispatch
    };

    const cartAndCheckoutCommonProps = {
        orderSummaryIntl,
        orderTotals: cartData,
        source,
        deliveryChargeFlag,
        handleRoundTripAndEstimateFlow,
        isRatesFromTier2Radius,
        isDelivery,
        isInStorePickupFromSessionStorage
    };

    switch (source) {
        case FACTORYSOURCE.CART:
            return <CartSummaryView {...cartAndCheckoutCommonProps} showShimmer={isEstimatesLoading} variant={FACTORYSOURCE.CART}/>;
        case FACTORYSOURCE.CHECKOUT:
            return (
                <CheckoutSummaryView
                    {...cartAndCheckoutCommonProps}
                    currentStep={currentStep}
                    formStep={formStep}
                    shouldShowTbdAlert={shouldShowTbdAlert}
                    showShimmer={isEstimatesLoading}
                    variant={FACTORYSOURCE.CHECKOUT}
                />
            );
        case FACTORYSOURCE.QUOTE:
            return (
                <QuoteSummaryView
                    orderSummaryIntl={orderSummaryIntl}
                    orderTotals={quotesData}
                    source={source}
                    shouldShowTbdAlert={shouldShowTbdAlert}
                    quoteDetailsData={quoteDetailsData}
                />
            );
        default:
            null;
    }
};

export default memo(OrderSummaryFactory);

OrderSummaryFactory.propTypes = {
    title: string,
    source: string,
    formStep: object,
    currentStep: number
};
OrderSummaryFactory.defaultProps = {
    title: '',
    source: '',
    formStep: {},
    currentStep: 1
};
