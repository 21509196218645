import { uuid4 } from '@sentry/utils';
import useCartEstimate from '../components/App/hooks/useCartEstimate';
import useAnalytics from './useAnalytics';
import { useUserContext } from '../aem-core-components/context/UserContext';
import { useFilterState } from '../components/cap';
import { useAnalyticsContext } from '../config/GoogleTagManagerEvents';
import { useCartState } from '../contexts/cart';
import useComputeLocation from './useComputeLocation';
import { getCustomAccountFields } from '../components/checkoutv2/checkoutAndOrderSummary/api/getCheckoutAPIs';
import { useCheckAuthorityType, useCheckUser } from './useCheckUser';
import { useCookieValue } from '../aem-core-components/utils/hooks';
import axiosInstance from '../../main/api/axiosDotComInstance';
import {
    checkIsEditQuoteFlow,
    getURLParams,
    isPaymentSectionVisited,
    validateZip,
    formatAccountProjects
} from '../components/global/utils/commonUtils';
import { isValidString, logError } from '../components/global/utils/logger';
import {
    RESET_ACCESSORIES_AND_ADDONS,
    RESET_EDIT_ON_CHECKOUT_COMPLETE,
    RESET_GEOGRAPHY,
    RESET_HTGO_FROM_YOUR_DETAILS,
    RESET_ORDER_ESTIMATES,
    RESET_PAYMENT,
    RESET_PROJECT_DETAILS,
    SET_OPTIONAL_PLANS_CHECKED,
    SET_OPTIONAL_PLANS_IS_EDIT,
    SET_SOURCES_LOADING_FLAG,
    SET_VIEW_CART_FIELDS,
    UPDATE_PROJECT
} from '../aem-core-components/actions/constants';
import {
    ACCESSORIES_AND_ADDONS,
    DUKE_CORP_LINK,
    FORM_STEP_CASH,
    FORM_STEP_CASH_ADDONS,
    FORM_STEP_CASH_ADDONS_WO_OPTIONAL,
    FORM_STEP_CASH_WO_OPTIONAL,
    FORM_STEP_CREATE_QUOTE,
    FORM_STEP_CREATE_QUOTE_ADDONS,
    FORM_STEP_CREATE_QUOTE_ADDONS_WO_OPTIONAL,
    FORM_STEP_CREATE_QUOTE_WO_OPTIONAL,
    FORM_STEP_CREDIT_ADDONS,
    FORM_STEP_CREDIT_ADDONS_WO_OPTIONAL,
    FORM_STEP_CREDIT_JOBSITE,
    FORM_STEP_CREDIT_WO_OPTIONAL,
    FORM_STEP_CUSTOM_CREDIT,
    FORM_STEP_CUSTOM_CREDIT_ADDONS,
    FORM_STEP_CUSTOM_CREDIT_ADDONS_WO_OPTIONAL,
    FORM_STEP_CUSTOM_CREDIT_WO_OPTIONAL,
    FORM_STEP_GUEST,
    FORM_STEP_GUEST_ADDONS,
    FORM_STEP_GUEST_ADDONS_WO_OPTIONAL,
    FORM_STEP_GUEST_WO_OPTIONAL,
    FORM_STEP_CREDIT_ADDRESS,
    FORM_STEP_CREDIT_ADDONS_ADDRESS,
    FORM_STEP_CREDIT_ADDONS_WO_OPTIONAL_ADDRESS,
    FORM_STEP_CREDIT_WO_OPTIONAL_ADDRESS,
    FORM_STEP_CUSTOM_CREDIT_WO_OPTIONAL_ADDRESS,
    FORM_STEP_CUSTOM_CREDIT_ADDONS_WO_OPTIONAL_ADDRESS,
    FORM_STEP_CUSTOM_CREDIT_ADDONS_ADDRESS,
    FORM_STEP_CUSTOM_CREDIT_ADDRESS,
    HTGO,
    OPTIONAL_PLANS,
    PAYMENT,
    PROJECT_DETAILS,
    YOUR_DETAILS,
    FORM_STEP_CREDIT_CREATE_QUOTE,
    FORM_STEP_CREDIT_CREATE_QUOTE_ADDONS,
    FORM_STEP_CREDIT_CREATE_QUOTE_WO_OPTIONAL,
    FORM_STEP_CREDIT_CREATE_QUOTE_ADDONS_WO_OPTIONAL
} from '../components/checkoutv2/constants';
import { AUTHORITY_TYPE } from '../components/global/constants';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../constants/analyticsConstants/Variables';
import { STORAGE_CONFIG } from '../constants/storageConfig';
import { USER_TYPE } from '../constants/userDetailsConstants';
import config from '../components/App/config';
import { API_CONFIG } from '../constants/apiConfig';
import { ENV_CONFIG } from '../constants/envConfig';

const useCheckout = () => {
    /**
     * function to reset particular steps data from context
     * @param {Object} formStepObj
     * @param {Number} stepToEdit
     */
    const [{ cart, isCreditNewAddress, optionalPlan, clickedMakeChanges, consumables }, dispatch] = useCartState();
    const [{ viewCart, projectDetails }, filterDispatch] = useFilterState();

    const { getPC } = useComputeLocation();
    const [{ getEstimates }] = useCartEstimate();
    const [{ isUserProfileLoading, userProfile }, { getProjectsFromCookie, getAccountProjectsDotCom }] =
        useUserContext();
    const projectInfoCookies = getProjectsFromCookie();
    const { sendEventsForEcommerceAction, sendEventsForCtaClicked } = useAnalyticsContext();
    const [{ payloadEcommerceActionAnalytics }] = useAnalytics();
    const userType = useCheckUser();
    const params = getURLParams();
    const authorityType = useCheckAuthorityType();
    const [guestUserId, setGuestUserId] = useCookieValue('guest_user_id');
    const { fuelCharges } = cart?.estimatesResponse?.estimate?.totals || {};
    const { remainingAccessories, totalAddOns } = consumables?.availableQuantity || '';
    const { PROJECT_SITE_NEW_URL, P2P_JOBSITE_URL } = API_CONFIG;

    const refreshCart = async () => {
        if (!validateZip(viewCart?.jobSiteZip) && userType !== USER_TYPE.CREDIT) {
            // Added code to prevent estimates with invalid zip on checkout step 1 DOTC-30234
            filterDispatch({
                type: RESET_GEOGRAPHY
            });
            dispatch({
                type: RESET_ORDER_ESTIMATES
            });
            return;
        }
        if (
            cart?.items?.length &&
            ((viewCart?.lat && viewCart?.long) || (userType === USER_TYPE.CREDIT && !isCreditNewAddress)) &&
            !isUserProfileLoading
        ) {
            const { pc, pcLat, pcLong } = viewCart || {};
            if (pc && pcLat && pcLong) {
                if (userType === USER_TYPE.CREDIT && !isCreditNewAddress) {
                    filterDispatch({ type: UPDATE_PROJECT, ...projectDetails, locationPC: pc });
                } else {
                    filterDispatch({ type: SET_VIEW_CART_FIELDS, key: 'pc', value: pc });
                }
                dispatch({
                    type: SET_SOURCES_LOADING_FLAG,
                    isSourcesLoading: false
                });
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISSOURCESLOADING, false);
                getEstimates('', pc, pcLat, pcLong, false, cart?.availableCartItems);
                sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_ESTIMATES_LOADING)
            }
        }
    };

    const clearCheckout = (formStepObj, stepToEdit, isGuestUser = false) => {
        dispatch({ type: RESET_EDIT_ON_CHECKOUT_COMPLETE, isCheckoutEditResetComplete: false });
        for (let item in formStepObj) {
            if (formStepObj[item] > stepToEdit) {
                switch (item) {
                    case YOUR_DETAILS:
                        break;
                    case HTGO:
                        clearInterval(viewCart?.checkPCInterval);
                        if (isGuestUser) {
                            dispatch({ type: RESET_HTGO_FROM_YOUR_DETAILS });
                        }
                        break;
                    case ACCESSORIES_AND_ADDONS:
                        dispatch({ type: RESET_ACCESSORIES_AND_ADDONS });
                        break;
                    case OPTIONAL_PLANS:
                        dispatch({
                            type: SET_OPTIONAL_PLANS_CHECKED,
                            isRPPChecked: optionalPlan?.initialRppSelection,
                            isFuelChargeChecked: optionalPlan?.initialFuelChargeSelection
                        });
                        dispatch({
                            type: SET_OPTIONAL_PLANS_IS_EDIT,
                            isEdit: false
                        });
                        break;
                    case PAYMENT:
                        dispatch({ type: RESET_PAYMENT });
                        break;
                    case PROJECT_DETAILS:
                        dispatch({ type: RESET_PROJECT_DETAILS });
                        break;
                    default:
                        break;
                }
            }
        }
        // dispatch({ type: RESET_EDIT_ON_CHECKOUT_COMPLETE, isCheckoutEditResetComplete: true });
    };

    const getCheckoutStepDetails = (formStep, currentStep, reservation = false) => {
        let currentStepName;
        if (reservation) {
            currentStepName = Object.keys(formStep)[0];
        } else {
            currentStepName = Object.keys(formStep)[currentStep - 1];
        }
        return {
            form_name: `${VARIABLE_CONFIG.CHECKOUT_PAGE_TITLE[currentStepName]}`,
            checkout_step: currentStep
        };
    };

    const checkoutFormStart = (elem, formStep, currentStep) => {
        if (
            elem?.target?.ariaLabel == VARIABLE_CONFIG.EVENT_LABEL.ARIA_LABEL_SAVE_AND_CONTINUE &&
            Object.keys(formStep)[currentStep - 1] != ACCESSORIES_AND_ADDONS
        ) {
            // match save and continue aria label
            const additionalPayload = {
                link_name: VARIABLE_CONFIG.EVENT_LABEL.SAVE_AND_CONTINUE,
                link_type: VARIABLE_CONFIG.TYPE.BUTTON,
                ...getCheckoutStepDetails(formStep, currentStep)
            };
            sendEventsForEcommerceAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FORM_SUBMITTED,
                payloadEcommerceActionAnalytics(),
                additionalPayload
            );
        }
    };

    const checkoutStepPayload = (formStep, currentStep, stepTypeValue, reservation = false) => {
        const displayQuoteId = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.DISPLAY_QUOTE_ID) || '';
        const isEditQuoteFlow = checkIsEditQuoteFlow();
        let currentStepName;
        // 'reservation' will be true only when we trigger event for submit-reservation
        if (reservation) {
            currentStepName = Object.keys(formStep)[0];
        } else {
            // 'stepTypeValue' will be 0 if we trigger step_viewed event
            // 'stepTypeValue' will be 1 if we trigger step_completed event
            currentStepName = Object.keys(formStep)[currentStep - (stepTypeValue + 1)];
        }
        const checkoutSteps = {
            page_title: `${VARIABLE_CONFIG.CHECKOUT_PAGE_TITLE[currentStepName]}`,
            page_location: `${VARIABLE_CONFIG?.CHECKOUT_PAGE_LOCATION[currentStepName]}`,
            step_number: currentStep - stepTypeValue
        };
        if (isEditQuoteFlow) {
            checkoutSteps.quote_id = displayQuoteId;
        }
        return checkoutSteps;
    };

    const checkoutErrorAnalyticsEvent = (formStep, currentStep, formError, formField) => {
        const additionalPayload = {
            form_error: formError,
            form_field: formField,
            ...getCheckoutStepDetails(formStep, currentStep)
        };
        sendEventsForEcommerceAction(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FORM_ERROR,
            payloadEcommerceActionAnalytics(),
            additionalPayload
        );
    };

    const getCustomerId = () => {
        try {
            const userProfileLocalStorage = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.USERPROFILE);
            const userProfileObj = userProfileLocalStorage ? JSON.parse(userProfileLocalStorage) : {};
            let customerID;

            if (userType === USER_TYPE.CASH) {
                customerID = userProfileObj?.oktaUserId
                    ? `${userProfileObj?.oktaUserId}${uuid4().substring(0, 4)}`
                    : uuid4();
            } else {
                if (!isValidString(guestUserId)) {
                    let newGuestId = uuid4().substring(0, 25);
                    setGuestUserId(newGuestId);
                    customerID = newGuestId;
                } else {
                    customerID = guestUserId;
                }
            }

            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.CUSTIDENT, customerID);
            return customerID;
        } catch (error) {
            logError(error, false, 'getCustomerId', []);
        }
    };

    // check if any accessories and addons are available to show consumables step in checkout page
    const isAccessoriesAddonsAvailable = () => {
        try {
            if (isPaymentSectionVisited() && !clickedMakeChanges) {
                return sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ISADDONVISIBLE)
                    ? JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ISADDONVISIBLE))
                    : false;
            } else if (
                (remainingAccessories && Object.keys(remainingAccessories)?.length > 0) ||
                (totalAddOns && Object.keys(totalAddOns)?.length > 0)
            ) {
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISADDONVISIBLE, true);
                return true;
            } else {
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISADDONVISIBLE, false);
                return false;
            }
        } catch (error) {
            logError(error, false, 'isAccessoriesAddonsAvailable', []);
            return false;
        }
    };

    const getFormStep = (currentStep, setFormStep, isCustomAccount) => {
        try {
            let formStepCredit;
            let formStepCreditAddons;
            let formStepCreditAddonsWoOptional;
            let formStepCreditWoOptional;
            let formStepCustomCreditWoOptional;
            let formStepCustomCreditAddonsWoOptional;
            let formStepCustomCreditAddons;
            let formStepCustomCredit;
            if (
                isCreditNewAddress ||
                isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREATEJOBSITE))
            ) {
                formStepCredit = FORM_STEP_CREDIT_ADDRESS;
                formStepCreditAddons = FORM_STEP_CREDIT_ADDONS_ADDRESS;
                formStepCreditAddonsWoOptional = FORM_STEP_CREDIT_ADDONS_WO_OPTIONAL_ADDRESS;
                formStepCreditWoOptional = FORM_STEP_CREDIT_WO_OPTIONAL_ADDRESS;
                formStepCustomCreditWoOptional = FORM_STEP_CUSTOM_CREDIT_WO_OPTIONAL_ADDRESS;
                formStepCustomCreditAddonsWoOptional = FORM_STEP_CUSTOM_CREDIT_ADDONS_WO_OPTIONAL_ADDRESS;
                formStepCustomCreditAddons = FORM_STEP_CUSTOM_CREDIT_ADDONS_ADDRESS;
                formStepCustomCredit = FORM_STEP_CUSTOM_CREDIT_ADDRESS;
            } else {
                formStepCredit = FORM_STEP_CREDIT_JOBSITE;
                formStepCreditAddons = FORM_STEP_CREDIT_ADDONS;
                formStepCreditAddonsWoOptional = FORM_STEP_CREDIT_ADDONS_WO_OPTIONAL;
                formStepCreditWoOptional = FORM_STEP_CREDIT_WO_OPTIONAL;
                formStepCustomCreditWoOptional = FORM_STEP_CUSTOM_CREDIT_WO_OPTIONAL;
                formStepCustomCreditAddonsWoOptional = FORM_STEP_CUSTOM_CREDIT_ADDONS_WO_OPTIONAL;
                formStepCustomCreditAddons = FORM_STEP_CUSTOM_CREDIT_ADDONS;
                formStepCustomCredit = FORM_STEP_CUSTOM_CREDIT;
            }
            if (userType === USER_TYPE.CREDIT && authorityType === AUTHORITY_TYPE.P2P) {
                const isNewAddressOrCreateJobSite =
                    isCreditNewAddress ||
                    isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREATEJOBSITE));
                const isOptionalVisible = optionalPlan.isRPPCheckboxVisible || optionalPlan.isFuelCheckboxVisible;
                if (isNewAddressOrCreateJobSite) {
                    if (isOptionalVisible) {
                        setFormStep(
                            isAccessoriesAddonsAvailable() && currentStep > formStepCredit.CREATE_JOBSITE
                                ? FORM_STEP_CREDIT_CREATE_QUOTE_ADDONS
                                : FORM_STEP_CREDIT_CREATE_QUOTE
                        );
                    } else {
                        setFormStep(
                            isAccessoriesAddonsAvailable() && currentStep > formStepCredit.CREATE_JOBSITE
                                ? FORM_STEP_CREDIT_CREATE_QUOTE_ADDONS_WO_OPTIONAL
                                : FORM_STEP_CREDIT_CREATE_QUOTE_WO_OPTIONAL
                        );
                    }
                } else {
                    if (isOptionalVisible) {
                        setFormStep(
                            isAccessoriesAddonsAvailable() && currentStep > formStepCredit.HTGO
                                ? FORM_STEP_CREATE_QUOTE_ADDONS
                                : FORM_STEP_CREATE_QUOTE
                        );
                    } else {
                        setFormStep(
                            isAccessoriesAddonsAvailable() && currentStep > formStepCredit.HTGO
                                ? FORM_STEP_CREATE_QUOTE_ADDONS_WO_OPTIONAL
                                : FORM_STEP_CREATE_QUOTE_WO_OPTIONAL
                        );
                    }
                }
            } else if (userType === USER_TYPE.CREDIT && !isCustomAccount) {
                if (optionalPlan.isRPPCheckboxVisible || optionalPlan.isFuelCheckboxVisible) {
                    setFormStep(
                        isAccessoriesAddonsAvailable() && currentStep > formStepCredit.HTGO
                            ? formStepCreditAddons
                            : formStepCredit
                    );
                } else {
                    setFormStep(
                        isAccessoriesAddonsAvailable() && currentStep > formStepCredit.HTGO
                            ? formStepCreditAddonsWoOptional
                            : formStepCreditWoOptional
                    );
                }
            } else if (userType === USER_TYPE.CREDIT && isCustomAccount) {
                if (optionalPlan.isRPPCheckboxVisible || optionalPlan.isFuelCheckboxVisible) {
                    setFormStep(
                        isAccessoriesAddonsAvailable() && currentStep > formStepCredit.HTGO && isCustomAccount
                            ? formStepCustomCreditAddons
                            : formStepCustomCredit
                    );
                } else {
                    setFormStep(
                        isAccessoriesAddonsAvailable() && currentStep > formStepCredit.HTGO && isCustomAccount
                            ? formStepCustomCreditAddonsWoOptional
                            : formStepCustomCreditWoOptional
                    );
                }
            } else if (userType === USER_TYPE.CASH) {
                if (optionalPlan.isRPPCheckboxVisible || optionalPlan.isFuelCheckboxVisible) {
                    setFormStep(
                        isAccessoriesAddonsAvailable() && currentStep > FORM_STEP_CASH.HTGO
                            ? FORM_STEP_CASH_ADDONS
                            : FORM_STEP_CASH
                    );
                } else {
                    setFormStep(
                        isAccessoriesAddonsAvailable() && currentStep > FORM_STEP_CASH.HTGO
                            ? FORM_STEP_CASH_ADDONS_WO_OPTIONAL
                            : FORM_STEP_CASH_WO_OPTIONAL
                    );
                }
            } else if (userType === USER_TYPE.GUEST) {
                if (optionalPlan.isRPPCheckboxVisible || optionalPlan.isFuelCheckboxVisible) {
                    setFormStep(
                        isAccessoriesAddonsAvailable() && currentStep > FORM_STEP_GUEST.HTGO
                            ? FORM_STEP_GUEST_ADDONS
                            : FORM_STEP_GUEST
                    );
                } else {
                    setFormStep(
                        isAccessoriesAddonsAvailable() && currentStep > FORM_STEP_CASH.HTGO
                            ? FORM_STEP_GUEST_ADDONS_WO_OPTIONAL
                            : FORM_STEP_GUEST_WO_OPTIONAL
                    );
                }
            }
        } catch (error) {
            logError(error, false, 'getFormStep', [currentStep, setFormStep, isCustomAccount]);
        }
    };

    const customBillingFieldsCall = (setCustomBillingFields, setIsCustomAccount) => {
        try {
            if (userType === USER_TYPE.CREDIT && projectInfoCookies?.CurrentWynneAccount) {
                const corpLink = userProfile?.accounts?.find(
                    account => account?.id == projectInfoCookies?.CurrentWynneAccount
                )?.corplink;
                const apiFunction = async () => {
                    try {
                        const response = await getCustomAccountFields(
                            projectInfoCookies?.CurrentWynneAccount,
                            parseInt(corpLink)
                        );
                        setCustomBillingFields(response?.data?.data);
                        if (response?.data?.data?.customBillingFields?.length !== 0) {
                            setIsCustomAccount(true);
                        } else {
                            setIsCustomAccount(false);
                        }
                    } catch (err) {
                        logError(err, false, 'apiFunction');
                    }
                };
                if (corpLink) {
                    if (corpLink !== DUKE_CORP_LINK) {
                        apiFunction();
                    } else if (corpLink === DUKE_CORP_LINK) {
                        setIsCustomAccount(true);
                        setCustomBillingFields({});
                    }
                }
            }
            window.addEventListener(
                'pageshow',
                function (evt) {
                    if (evt.persisted) {
                        setTimeout(function () {
                            window.location.reload();
                        }, 10);
                    }
                },
                false
            );
        } catch (error) {
            logError(error, false, 'customBillingFieldsCall', [setCustomBillingFields, setIsCustomAccount]);
        }
    };

    const handleEditInCart = (labelName,linkDestination) => {
        sendEventsForCtaClicked(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_CLICKED, labelName, VARIABLE_CONFIG.TYPE.BUTTON, VARIABLE_CONFIG.EVENT_LINK_LOCATION.BODY, linkDestination);
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.IS_CAP_IN_EDIT_MODE, true);
        window.location.href = window.location.origin + config.pagePaths.cartPage;
    };

    const addProject = async (projectDetails, companyID) => {
        const url = authorityType === AUTHORITY_TYPE.P2P ? P2P_JOBSITE_URL : PROJECT_SITE_NEW_URL;
        try {
            const { error } = await axiosInstance.post(url, projectDetails);
            if (error) {
                logError(error, false, 'addProject', [projectDetails]);
                return { projectData: null, error: error };
            } else {
                const { projectData } = await getAccountProjectsDotCom(projectDetails?.accountId, '', true);
                for (let i in projectData) {
                    if (projectData[i]?.jobName?.toLowerCase() === projectDetails?.projectName?.toLowerCase()) {
                        return { projectData: projectData[i], error: error };
                    }
                }
                return { projectData: projectData?.[0], error: error };
            }
        } catch (error) {
            logError(error, false, 'addProject', [projectDetails]);
            return {
                projectData: null,
                error: error?.response?.data?.error ?? error,
                addressInfo: error?.response?.data?.addressInfo
            };
        }
    };

    const editProject = async projectDetails => {
        const url = PROJECT_SITE_NEW_URL;
        try {
            const { data } = await axiosInstance.put(url, projectDetails, {
                headers: {
                    ipAddress:
                        isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.IP_ADDRESS)) ||
                        ENV_CONFIG.IP_ADDRESS_DEFAULT
                }
            });
            const jobsiteError = data?.error;
            const editJobsiteData = data?.data || [];
            if (jobsiteError) {
                logError(jobsiteError, false, 'editProject', [projectDetails]);
                return { projectData: null, error: jobsiteError };
            } else {
                return { projectData: formatAccountProjects(editJobsiteData)?.[0], error: jobsiteError };
            }
        } catch (error) {
            logError(error, false, 'editProject', [projectDetails]);
            return {
                projectData: null,
                error: error?.response?.data?.error ?? error
            };
        }
    };

    const getCheckoutStepLink = (formStep, currentStep, reservation = false) => {
        let currentStepName;
        if (reservation) {
            currentStepName = Object.keys(formStep)[0];
        } else {
            currentStepName = Object.keys(formStep)[currentStep - 1];
        }
        return VARIABLE_CONFIG.CHECKOUT_PAGE_LOCATION[currentStepName];
    };

    return {
        clearCheckout,
        refreshCart,
        checkoutStepPayload,
        getCheckoutStepDetails,
        checkoutFormStart,
        checkoutErrorAnalyticsEvent,
        getCustomerId,
        getFormStep,
        isAccessoriesAddonsAvailable,
        customBillingFieldsCall,
        handleEditInCart,
        addProject,
        editProject,
        getCheckoutStepLink
    };
};

export default useCheckout;
